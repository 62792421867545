import React from "react"
import { Row, Col, Container } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { FacebookShareButton, TwitterShareButton } from 'react-share';
import FlexibleContentFeatured from "components/FlexibleContent/FlexibleContentFeatured"
import Carousel from "components/FlexibleContent/Blocks/HeroCarousel/Carousel"
import Article from "components/shared/Article"
import SidebarBlock from "components/shared/SidebarBlock"
import ContainerMax from "components/shared/ContainerMax"

const ContainerStyled = styled(Container)`
    padding-right: 0;
    padding-left: 0;

    @media ${media.md} {
        padding-right: 15px;
    }
`

const FeaturedBlock = (props) => {
    const { data } = props
    // let link = ""
    // if (typeof window !== "undefined") link = window.location.href
    return(
        <>
            {data.template.acfFeatureArticle.slides !== null &&
                <ContainerMax maxWidth="1920" noPadding>
                    <Carousel slides={data.template.acfFeatureArticle.slides} />
                </ContainerMax>
            }
            <ContainerStyled className="py-4">
                <Article>
                    <Row>
                        <Col lg="8">
                            <Row>
                                {data.date &&
                                    <Col xs={12}>
                                        <div className="date-share clearfix">
                                            <p className="date">{data.date}</p>
                                            {/* <div className="share">
                                                <FacebookShareButton className="share-button" url={link}><FontAwesomeIcon icon={faFacebookF} /></FacebookShareButton>
                                                <TwitterShareButton className="share-button" url={link}><FontAwesomeIcon icon={faTwitter} /></TwitterShareButton>
                                            </div> */}
                                        </div>
                                    </Col>
                                }
                                <Col xs={12} className="d-flex flex-wrap">
                                    <FlexibleContentFeatured blocks={data.template.acfTwoColumnFlexibleContent.twoColumnBlocks} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={{ size: 3, offset: 1 }} className="sidebar">
                            <aside>
                                {data.template.acfTwoColumnFlexibleContent.sidebar && data.template.acfTwoColumnFlexibleContent.sidebar.map((node, i) => {
                                    switch (node.acfSidebarBlocks.sidebarBlockType[0].__typename) {
                                        case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_ImageLink":
                                            return(
                                                <Col xs={6} md={4} lg={12} className="pb-3 pb-md-0 px-1 px-lg-0" key={i}>
                                                    <SidebarBlock id={node.databaseId} margin={true} />
                                                </Col>
                                            )

                                        case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_Rolex":
                                            return(
                                                <Col xs={6} md={4} lg={12} className="d-none d-lg-block pb-3 pb-md-0 px-1 px-lg-0" key={i}>
                                                    <SidebarBlock id={node.databaseId} margin={true} />
                                                </Col>
                                            )
                                    
                                        default:
                                            return "";
                                    }
                                })}
                            </aside>
                        </Col>
                    </Row>
                </Article>
            </ContainerStyled>
        </>
    )
}

export default FeaturedBlock