import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import FeaturedLayout from "components/FeaturedLayout/FeaturedLayout"

class FeaturedTemplate extends Component {
    render() {
        const data = this.props.data.wpPage
        console.log(data)
        const title = data.seo.title || data.title
        return (
            <Layout title={title} description={data.seo.metaDesc} path={data.uri}>
                <FeaturedLayout data={data} />
            </Layout>
        )
    }
}

export const query = graphql`
query($id: String!) {
    wpPage(id: {eq: $id}) {
        title
        uri
        seo {
            title
            metaDesc
        }
        template {
            ... on WpTemplate_FeatureArticle {
                ...featuredTemplateQuery
            }
        }
    }
}
`

export default FeaturedTemplate